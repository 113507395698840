import axios from 'axios';
import applyConverters from 'axios-case-converter';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const token = cookies.get('token');

export const appRequest = applyConverters(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_SITE_URL}/api/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const request = applyConverters(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const authRequest = applyConverters(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
);

export const baseAuthRequest = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  },
});
