export class Enum {
  constructor(enums) {
    this.enums = enums;
    this.values = Object.values(enums);
    for (const prop in enums) {
      if (enums.hasOwnProperty(prop)) {
        this[prop] = enums[prop];
      }
    }
  }

  // Copy over enum value for other usage, i.e. Enum.UserGender.MALE

  getId = (enumValue) => {
    const ret = null;
    if (enumValue) {
      return enumValue.id;
    }
    return ret;
  };

  getEnum = (id) => {
    return this.values.find((e) => {
      return e.id === id;
    });
  };

  getEnumByKey = (value, valueKey) => {
    return this.values.find((e) => {
      return e[valueKey || 'id'] === value;
    });
  };

  getLabel = (id) => {
    const en = this.values.find((e) => {
      return e.id === id;
    });
    if (en) {
      return en.label;
    }
    return null;
  };

  getEnumFromValue = (value, valueName) => {
    return this.values.find((e) => {
      return e[valueName] === value;
    });
  };

  getIds = (enumValues) => {
    const ret = [];
    if (enumValues) {
      enumValues.forEach((val, i) => {
        const enumId = this.getId(val);
        if (enumId) {
          ret.push(enumId);
        }
      });
    }
    return ret;
  };

  getEnums = (ids) => {
    const ret = [];
    if (ids) {
      ids.forEach((enumId, i) => {
        const enumVal = this.getEnum(enumId);
        if (enumVal) {
          ret.push(enumVal);
        }
      });
    }
    return ret;
  };
}

export const BriefRegistrationStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  SUBMITTED: { id: 2, label: 'Submitted' },
});

export const CallStatus = new Enum({
  SCHEDULED: { id: 1, label: 'Scheduled' },
  CANCELLED: { id: 2, label: 'Cancelled' },
  RESCHEDULED: { id: 3, label: 'Rescheduled' },
  DRAFT: { id: 4, label: 'Draft' },
});

export const ProspectStatus = new Enum({
  DRAFT: { id: 1, label: 'Draft' },
  CONVERTED: { id: 2, label: 'Converted' },
  SUBMITTED: { id: 3, label: 'Submitted' },
});

export const EngagementStatus = new Enum({
  MATCH: { id: 2, label: 'Match' },
  TRIAL: { id: 3, label: 'Trial' },
  ACTIVE: { id: 4, label: 'Active' },
  COMPLETED: { id: 5, label: 'Completed' },
  CANCELLED: { id: 6, label: 'Cancelled' },
});

export const Role = new Enum({
  CLIENT: { id: 1, label: 'Client' },
  TALENT: { id: 2, label: 'Talent' },
});

export const OrganizationType = new Enum({
  ENTERPRISE: { id: 1, label: 'Enterprise' },
  PROFESSIONAL: { id: 2, label: 'Professional' },
});

export const ServiceRateType = new Enum({
  HOUR: { id: 1, label: 'per hour' },
  DAY: { id: 2, label: 'per day' },
  WEEK: { id: 3, label: 'per week' },
  MONTH: { id: 4, label: 'per month' },
  QUARTER: { id: 5, label: 'per quarter' },
  YEAR: { id: 6, label: 'per year' },
  PROJECT: { id: 7, label: 'per project' },
});

export const PortfolioType = {
  USER: 0,
  ORGANIZATION: 1,
  TEAM: 2,
  RESUME: 3,
  PORTFOLIO: 4,
  PUBLIC_RESUME: 5,
  PUBLIC_PORTFOLIO: 6,
  TALENT: 7,
  TEAM_SLUG: 8,
};

export const PopupType = {
  FULL_PAGE_MODAL: 'full',
  BOTTOM_PAGE_MODAL: 'bottom',
};

export const Site = new Enum({
  CONTENTCO: { id: 1, label: 'Content.co' },
  HIREDIGITAL: { id: 2, label: 'Hire Digital' },
});

export const PreferredPlatform = new Enum({
  PHONE: { id: 1, label: 'Phone' },
  ZOOM: { id: 2, label: 'Zoom' },
  SKYPE: { id: 3, label: 'Skype' },
  WEB_CALL: { id: 4, label: 'Web call' },
});

export const Currency = new Enum({
  sgd: { id: 'SGD', code: 'SGD', label: 'Singapore Dollar', icon: 'S$', flag: 'sg' },
  eur_sgd: { id: 'EUR', code: 'EUR', label: 'Euro', icon: '€', flag: 'eu' },
  gbp_sgd: { id: 'GBP', code: 'GBP', label: 'British Pound', icon: '£', flag: 'gb' },
  usd_sgd: { id: 'USD', code: 'USD', label: 'US Dollar', icon: '$', flag: 'us' },
  aud_sgd: { id: 'AUD', code: 'AUD', label: 'Australian Dollar', icon: 'AU$', flag: 'au' },
  cad_sgd: { id: 'CAD', code: 'CAD', label: 'Canadian Dollar', icon: 'C$', flag: 'ca' },
  cny_sgd_100: { id: 'CNY', code: 'CNY', label: 'Chinese Renminbi', icon: '¥', flag: 'cn' },
  hkd_sgd_100: {
    id: 'HKD',
    code: 'HKD',
    label: 'Hong Kong Dollar',
    icon: 'HK$',
    flag: 'hk',
  },
  inr_sgd_100: { id: 'INR', code: 'INR', label: 'Indian Rupee', icon: '₹' },
  idr_sgd_100: {
    id: 'IDR',
    code: 'IDR',
    label: 'Indonesian Rupiah',
    icon: 'Rp',
    flag: 'in',
  },
  jpy_sgd_100: { id: 'JPY', code: 'JPY', label: 'Japanese Yen', icon: '¥', flag: 'jp' },
  krw_sgd_100: { id: 'KRW', code: 'KRW', label: 'Korean Won', icon: '₩', flag: 'kr' },
  myr_sgd_100: {
    id: 'MYR',
    code: 'MYR',
    label: 'Malaysian Ringgit',
    icon: 'RM',
    flag: 'my',
  },
  twd_sgd_100: {
    id: 'TWD',
    code: 'TWD',
    label: 'New Taiwan Dollar',
    icon: 'NT$',
    flag: 'tw',
  },
  nzd_sgd: { id: 'NZD', code: 'NZD', label: 'New Zealand Dollar', icon: 'NZ$', flag: 'nz' },
  php_sgd_100: { id: 'PHP', code: 'PHP', label: 'Philippine Peso', icon: '₱', flag: 'ph' },
  qar_sgd_100: { id: 'QAR', code: 'QAR', label: 'Qatar Riyal', icon: '﷼', flag: 'qa' },
  sar_sgd_100: {
    id: 'SAR',
    code: 'SAR',
    label: 'Saudi Arabia Riyal',
    icon: '﷼',
    flag: 'sa',
  },
  chf_sgd: { id: 'CHF', code: 'CHF', label: 'Swiss Franc', icon: 'CHF', flag: 'ch' },
  thb_sgd_100: { id: 'THB', code: 'THB', label: 'Thai Baht', icon: '฿', flag: 'th' },
  aed_sgd_100: { id: 'AED', code: 'AED', label: 'UAE Dirham', icon: 'د.إ;', flag: 'ae' },
  vnd_sgd_100: { id: 'VND', code: 'VND', label: 'Vietnamese Dong', icon: '₫', flag: 'vn' },
});
