import { Currency } from './marketingEnum';
import axios from 'axios';

export const acceptedFiles = [
  'image/*',
  'application/zip',
  'application/pdf',
  'video/*',
  'application/vnd.ms-excel',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/docx',
  'application/doc',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

// export const portfolioAcceptedFiles = [
//   'image/*',
//   'application/pdf',
//   'video/*',
//   'application/msword', //.doc
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //.docx
// ];
export const portfolioAcceptedFiles = [
  'image/*',
  'application/pdf',
  'video/*',
  'application/msword', //.doc
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/docx',
  'application/doc',
];

export const copyTextToClipboard = (text) => {
  const el = document.createElement('textarea');
  document.body.appendChild(el);
  el.value = text;
  el.select();
  document.execCommand('copy');
  el.remove();
};

export const getName = (url) => {
  if (url) {
    return url.substring(url.lastIndexOf('/') + 1);
  }
  return;
};

export const ensureHttpPrefix = (value, prefix = 'http://') => {
  const httpPrefix = 'http://';
  const httpsPrefix = 'https://';
  const re = /^(https?):\/\//i;
  if (
    value &&
    !re.test(value) &&
    prefix.indexOf(value) !== 0 &&
    httpPrefix.indexOf(value) !== 0 &&
    httpsPrefix.indexOf(value) !== 0
  ) {
    return prefix + value;
  }

  return value;
};

export const toSentenceCase = (_string) => {
  // from Snake to Sentence
  return (
    _string.charAt(0).toUpperCase() +
    _string
      .slice(1)
      .replace(/([:\-_]+(.))/g, function (_, separator, letter, offset) {
        return offset ? ' ' + letter.toUpperCase() : letter;
      })
      .replace(/([A-Z])/g, ' $1')
  );
};

export const toTitleCase = (phrase) => {
  const exceptions = ['iOS', 'eCommerce'];
  return phrase
    .split(' ')
    .map((word) =>
      exceptions.indexOf(word) == -1 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    )
    .join(' ');
};

export const validatePhone = (phone) => {
  /*
  Simple phone validation 
  */
  if (/^[+\d]?(?:[+\d-.,#,*"\s()]*)$/.test(phone)) {
    return true;
  }
  return false;
};

export const formatNumber = (v, decimalPlaces = 2) => {
  return parseFloat(v)
    .toFixed(decimalPlaces)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const calculateConversion = (amount, rate) => {
  Math.log10 =
    Math.log10 ||
    function (x) {
      return Math.log(x) * Math.LOG10E;
    };

  let decimalPlaces = 0;
  let baseAmount = 1 * rate;
  let roundingDigits = parseInt(Math.log10(baseAmount));
  if (Math.abs(baseAmount) < 1) {
    roundingDigits -= 1;
  }
  let roundingDigitsAmount = Math.pow(10, roundingDigits);
  let roundingAmount = Math.round(baseAmount / roundingDigitsAmount) * roundingDigitsAmount;

  if (rate < 1.5 && rate > 0.5 && (amount * rate) % 1 !== 0) {
    decimalPlaces = 2;
    roundingAmount = 0.01;
  }

  return formatNumber(
    Math.abs(Math.round((amount * rate) / roundingAmount) * roundingAmount),
    decimalPlaces
  );
};

export const getCurrencyIcon = (code) => Currency.getEnumByKey(code, 'code')?.icon || '';

export const formatCurrency = (v, decimalPlaces = 2, symbol = '$') => {
  return `${symbol}${formatNumber(v, decimalPlaces)}`;
};

export const convertToCurrency = (amount = 0, currency = {}, showCode = false) => {
  const code = showCode ? currency?.code || '' : '';
  if (Object.keys(currency).length) {
    if (amount) {
      return `${currency?.icon || getCurrencyIcon(currency?.code)}${calculateConversion(
        amount,
        currency?.rate
      )} ${code}`.trim();
    }
    return `${currency?.icon || getCurrencyIcon(currency?.code)}0 ${code}`.trim();
  }

  return `S$${formatNumber(amount, 0)} ${code}`.trim();
};

export const socialPrefixes = {
  linkedin: 'https://www.linkedin.com/in/',
  twitter: 'https://twitter.com/',
  facebook: 'https://www.facebook.com/',
  instagram: 'https://www.instagram.com/',
};

export const addHttpPrefix = (value, prefix) => {
  // Need to add prefix if we don't have http:// prefix already AND we don't have part of it
  if (
    value &&
    !/^(https?):\/\//i.test(value) &&
    `http://${prefix}`.indexOf(value) !== 0 &&
    `https://${prefix}`.indexOf(value) !== 0
  ) {
    return `${prefix}${value}`;
  }
  return value || '';
};

export const setPageTitle = (title) => {
  document.title = title;
};

export const openUrl = (url, target = '_blank') => {
  return new Promise((resolve, reject) => {
    axios
      .head(url)
      .then(() => {
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('target', target);
        document.body.appendChild(a);
        a.click();
        a.remove();
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const formatBytes = (size, decimals = 2) => {
  if (!size) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(size) / Math.log(k));

  return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + units[i];
};

export const convertNumberToText = (v) => {
  const special = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];
  const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

  if (v < 20) return special[v];
  if (v % 10 === 0) return deca[Math.floor(v / 10) - 2] + 'ieth';

  return deca[Math.floor(v / 10) - 2] + 'y-' + special[v % 10];
};

export const convertToSeconds = (durationTimestamp) => {
  const stringVal = String(durationTimestamp) || '';
  const stringArr = stringVal.split(':');

  let hour = parseInt(stringArr[0]) || 0;
  let min = parseInt(stringArr[1]) || 0;
  let sec = parseInt(stringArr[2]) || 0;
  return hour * 60 * 60 + min * 60 + sec;
};

export const convertToHours = (totalSeconds, decimals = 3) => {
  return (totalSeconds / 3600).toFixed(decimals);
};

export const generateAddress = (address) => {
  let addressString = '';
  if (address.address) {
    addressString += `${address.address}, `;
  }
  if (address.city) {
    addressString += `${address.city}, `;
  }
  if (address.region) {
    addressString += `${address.region}, `;
  }
  if (address.postalCode) {
    addressString += `${address.postalCode}, `;
  }
  if (address.country) {
    addressString += `${address.country}, `;
  }
  return addressString.slice(0, -2);
};

const AVG_WORD_PER_MIN = 200;

export const extractTextOnly = (htmlString = '') => htmlString.replace(/<\/?[^>]+(>|$)/g, '');
export const getWordCount = (values = '') => values?.split(' ')?.length || 0;
export const getWordCountFromHtml = (htmlString = '') => getWordCount(extractTextOnly(htmlString));
export const estimateReadTime = (htmlString) => {
  const words = getWordCountFromHtml(htmlString);
  return Math.ceil(words / AVG_WORD_PER_MIN);
};

export const setValue = (entry, value) => {
  if (entry === undefined) {
    // eslint-disable-next-line
    console.warn({ err: new Error('entry is undefined on setValue') });
    return '';
  }
  // eslint-disable-next-line
  entry = entry.replace(/(\{(\w+)\})/g, (match, grp, key) => {
    const ret = value[key];
    if (ret !== null && ret !== undefined) {
      return ret;
    }
    return match;
  });

  return entry;
};

export const isOfTypeUuId = (value) => {
  const accessKeyRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
  return accessKeyRegex.test(value);
};

export const dateSort = (a, b) => {
  const bEnd = b.endYear ? new Date(b.endYear, b.endMonth).valueOf() : new Date().valueOf();
  const aEnd = a.endYear ? new Date(a.endYear, a.endMonth).valueOf() : new Date().valueOf();
  return bEnd < aEnd ? -1 : 1;
};

export const simplifyErrors = (errs) => {
  if (!errs) return {};
  return Object.keys(errs || {}).reduce((p, c) => {
    const err = errs[c][0];
    if (err) {
      p[c] = errs[c][0];
    }
    return p;
  }, {});
};
